import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo.component"
import SearchResults from "../../components/common/search/search-results"
import {
  AppFunctionComponent,
  CareerOffer,
  ListItem,
  QueryResultFlat,
  TextBuilder,
} from "../../types"
import { graphql } from "gatsby"
import { Menu } from "../../navigation/types/menu.interface"
import { Resource } from "../../components/community/resources/resource.interface"
import { DetectedBreadcrumbs, TopNavigationWrapper } from "../../navigation"
import { BlogPostPreview } from "../../components/community/blog/blog.interface"
import InstantSearchWrapper from "../../helpers/instant-search-wrapper"

type SearchProps = {
  data: {
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
    blogPosts: QueryResultFlat<BlogPostPreview>
    productsPage: {
      sections: {
        items: ListItem[]
      }[]
    }
    careers: {
      sections: {
        entryName: string
        items: CareerOffer[]
      }[]
    }
    resources: {
      nodes: Resource[]
    }
  }
}

const Search: AppFunctionComponent<SearchProps> = ({
  data: {
    footerNavigationContent: { navigationItems: footerNaviigationItems },
    promobarContent,
    topNavigationContent: { navigationItems: topNavigationItems },
    blogPosts,
    productsPage: { sections: productsSections },
    careers: { sections: careersSections },
    resources: { nodes: resources },
  },
}) => {
  return (
    <Layout
      footerNavigationItems={footerNaviigationItems}
      topNavigationItems={topNavigationItems}
      promobarContent={promobarContent}
    >
      <SEO title="Search" />
      <TopNavigationWrapper breadcrumbsMenu={<DetectedBreadcrumbs />} />
      <InstantSearchWrapper>
        <SearchResults
          blogPosts={blogPosts}
          productsPreview={productsSections[0].items}
          careersSections={
            careersSections.find(
              (section) => section.entryName === "Careers - vacancies"
            )!.items
          }
          resources={resources}
        />
      </InstantSearchWrapper>
    </Layout>
  )
}

export const query = graphql`
  query SearchQueryPL($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    blogPosts: allContentfulBlogPost(
      sort: { fields: postOriginalPublishDate, order: DESC }
    ) {
      nodes {
        id
        postSlug
        postTitle
        postOriginalPublishDate
        postOriginalPublishDateFormatted: postOriginalPublishDate(
          formatString: "MMM D, YYYY"
        )
        postTags {
          text
        }
        postExcerpt {
          postExcerpt
        }
        postFeaturedImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
            width: 600
          )
          title
          description
        }
      }
    }
    productsPage: contentfulPageBuilder(
      path: { eq: "/products" }
      node_locale: { eq: "pl-PL" }
    ) {
      sections {
        ...List
      }
    }
    careers: contentfulPageBuilder(
      path: { eq: "/career" }
      node_locale: { eq: "pl-PL" }
    ) {
      path
      sections {
        ... on ContentfulList {
          items {
            ... on ContentfulCareerOffer {
              id
              title
              jobType
              slug
              city
            }
          }
          entryName
        }
      }
    }
    resources: allContentfulResource(sort: { fields: createdAt, order: DESC }) {
      nodes {
        authors {
          name
        }
        category {
          slug
          title
        }
        description
        id
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
          )
        }
        muditaOwned
        tags {
          text
          slug
        }
        title
        sourceUrl
        forumThreadUrl
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: "pl-PL" }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: "pl-PL" }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "67Y5XVRqB0r7iJbO4N8zxx" }
      node_locale: { eq: "pl-PL" }
    ) {
      text {
        raw
      }
      node_locale
    }
  }
`
export default Search
